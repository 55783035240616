<template>
  <v-form>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h1 style="font-weight: 800; color: #e53935">จัดการข้อมูลผู้ใช้งาน</h1>
      </v-col>
      <v-col cols="12">
        <!-- <v-tabs color="basil">
          <v-tabs-slider color="#e53935"></v-tabs-slider>
          <v-tab
            v-for="item in tabitems"
            :key="item.key"
            @click="tab(item)"
            style="font-weight: 800; font-size: 24px"
          >
            <span>{{ item.text }}</span>
          </v-tab>
        </v-tabs> -->
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn @click="goToCreate()">เพิ่มผู้ใช้งาน</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersCategory"
            :items="items"
            :search="search"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:[`item.name`]="{ item }">
              <span>{{ item.firstName }} {{ item.lastName }}</span>
            </template>
            <template v-slot:[`item.password`]="{ item }">
              <span>{{ item.password }}</span>
            </template>
            <template v-slot:[`item.bookType`]="{ item }">
              <span v-if="item.bookType == 'menu0'">ทะเบียนหนังสือรับ</span>
              <span v-if="item.bookType == 'menu1'">ทะเบียนหนังสือส่ง</span>
              <span v-if="item.bookType == 'menu2'">ทะเบียนประกาศ</span>
              <span v-if="item.bookType == 'menu3'">ทะเบียนคำสั่ง</span>
              <span v-if="item.bookType == 'menu4'">ทะเบียนบันทึกข้อความ</span>
              <span v-if="item.bookType == 'menu5'">บัญชีหนังสือส่งเก็บ</span>
              <span v-if="item.bookType == 'menu6'">ทะเบียนหนังสือเก็บ</span>
              <span v-if="item.bookType == 'menu7'"
                >บัญชีส่งมอบหนังสือครบ ๒๐ ปี</span
              >
              <span v-if="item.bookType == 'menu8'"
                >บัญชีหนังสือครบ ๒๐ ปี ที่ขอเก็บเอง</span
              >
              <span v-if="item.bookType == 'menu9'">บัญชีฝากหนังสือ</span>
              <span v-if="item.bookType == 'menu10'">บัญชีหนังสือขอทำลาย</span>
            </template>
            <template v-slot:[`item.isView`]="{ item }">
              <v-icon v-if="item.isView" dense color="green">mdi-check</v-icon>
              <v-icon v-if="!item.isView" dense color="red">mdi-close</v-icon>
            </template>
            <template v-slot:[`item.isEdit`]="{ item }">
              <v-icon v-if="item.isEdit" dense color="green">mdi-check</v-icon>
              <v-icon v-if="!item.isEdit" dense color="red">mdi-close</v-icon>
            </template>
            <template v-slot:[`item.activeFlag`]="{ item }">
              <v-icon v-if="item.activeFlag" dense color="green"
                >mdi-check</v-icon
              >
              <v-icon v-if="!item.activeFlag" dense color="red"
                >mdi-close</v-icon
              >
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <v-icon @click="viewUser(item)">mdi-eye</v-icon>
                <v-icon @click="UpdateUser(item)">mdi-pencil</v-icon>
                <v-icon
                  @click="DeleteUser(item)"
                  v-show="item.username !== 'admin'"
                  >mdi-delete</v-icon
                >

                <!-- <v-col cols="4" class="mx-0 px-1"
                  ><v-btn @click="GetQuestions(item)"
                    ><v-icon>mdi-magnify</v-icon></v-btn
                  ></v-col
                > -->
              </v-row>
            </template>
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { Decode, Encode } from "@/services";
export default {
  data() {
    return {
      headersCategory: [
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "username", value: "username", align: "center" },
        { text: "password", value: "password", align: "center" },
        { text: "ชื่อ", value: "name", align: "center" },
        { text: "ส่วนการใช้งาน", value: "userType", align: "center" },
        { text: "รายการทะเบียนหนังสือ", value: "bookType", align: "center" },
        { text: "สิทธิการแก้ไข", value: "isEdit", align: "center" },
        { text: "สิทธิการดู", value: "isView", align: "center" },

        { text: "ใช้งาน", value: "activeFlag", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center", width: 200 },
      ],
      tabs: "",
      tabitems: [
        { text: "ผู้ใช้งานทั่วไป", key: "ผู้ใช้งานทั่วไป" },
        // { text: "แอดมิน", key: "แอดมิน" },
      ],
      items: [],
      search: "",
      count: 0,
    };
  },
  created() {
    this.year = JSON.parse(Decode.decode(localStorage.getItem("yearSelected")));
    this.getAllUsers();
  },
  methods: {
    async getAllUsers() {
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("usersaratangjai"))
      );
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users/findAllAdmin`,
        auth
        // 'http://localhost:8081/categories/'
      );
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
      }
      console.log("users", this.items);
    },
    async getAllAdmins() {
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("usersaratangjai"))
      );
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users/findAllAdmin`,
        auth
        // 'http://localhost:8081/categories/'
      );
      console.log("users", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
      }
    },
    viewUser(val) {
      localStorage.setItem("userdata", Encode.encode(val));
      //   console.log(val);
      this.$router.push("viewUser");
    },
    UpdateUser(val) {
      localStorage.setItem("userdata", Encode.encode(val));
      //   console.log(val);
      this.$router.push("editUser");
    },
    async DeleteUser(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        // cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(
            Decode.decode(localStorage.getItem("usersaratangjai"))
          );
          const auth = {
            headers: { Authorization: `Bearer ${user.token}` },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/users/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllUsers();
        }
      });
    },
    goToCreate() {
      this.$router.push("createUser");
    },
    tab(val) {
      if (val.text == "แอดมิน") {
        this.getAllAdmins();
      }
      if (val.text == "ผู้ใช้งานทั่วไป") {
        this.getAllUsers();
      }
    },
  },
};
</script>
